define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "@ember-decorators/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group follow-notifications">
    <label class="control-label">{{i18n "user.follow.label"}}</label>
  
    <div class="controls">
      <PreferenceCheckbox
        @labelKey="user.follow_notifications_options.allow_people_to_follow_me"
        @checked={{this.user.allow_people_to_follow_me}}
        class="pref-allow-people-to-follow-me"
      />
  
      <PreferenceCheckbox
        @labelKey="user.follow_notifications_options.notify_me_when_followed"
        @checked={{this.user.notify_me_when_followed}}
        class="pref-notify-me-when-followed"
      />
  
      <PreferenceCheckbox
        @labelKey="user.follow_notifications_options.notify_followed_user_when_followed"
        @checked={{this.user.notify_followed_user_when_followed}}
        class="pref-notify-followed-user-when-followed"
      />
  
      <PreferenceCheckbox
        @labelKey="user.follow_notifications_options.notify_me_when_followed_replies"
        @checked={{this.user.notify_me_when_followed_replies}}
        class="pref-notify-me-when-followed-replies"
      />
  
      <PreferenceCheckbox
        @labelKey="user.follow_notifications_options.notify_me_when_followed_creates_topic"
        @checked={{this.user.notify_me_when_followed_creates_topic}}
        class="pref-notify-me-when-followed-creates-topic"
      />
    </div>
  </div>
  */
  {
    "id": "XrzSsTnF",
    "block": "[[[10,0],[14,0,\"control-group follow-notifications\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"user.follow.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"pref-allow-people-to-follow-me\"]],[[\"@labelKey\",\"@checked\"],[\"user.follow_notifications_options.allow_people_to_follow_me\",[30,0,[\"user\",\"allow_people_to_follow_me\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"pref-notify-me-when-followed\"]],[[\"@labelKey\",\"@checked\"],[\"user.follow_notifications_options.notify_me_when_followed\",[30,0,[\"user\",\"notify_me_when_followed\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"pref-notify-followed-user-when-followed\"]],[[\"@labelKey\",\"@checked\"],[\"user.follow_notifications_options.notify_followed_user_when_followed\",[30,0,[\"user\",\"notify_followed_user_when_followed\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"pref-notify-me-when-followed-replies\"]],[[\"@labelKey\",\"@checked\"],[\"user.follow_notifications_options.notify_me_when_followed_replies\",[30,0,[\"user\",\"notify_me_when_followed_replies\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"pref-notify-me-when-followed-creates-topic\"]],[[\"@labelKey\",\"@checked\"],[\"user.follow_notifications_options.notify_me_when_followed_creates_topic\",[30,0,[\"user\",\"notify_me_when_followed_creates_topic\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences.hbs",
    "isStrictMode": false
  });
  const preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];
  class FollowNotificationPreferences extends _component.default {
    _updatePreferences() {
      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }
      preferences.forEach(p => {
        this.user.set(`custom_fields.${p}`, this.user[p]);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "_updatePreferences", [(0, _object.observes)(...preferences.map(p => `user.${p}`))]))();
  }
  _exports.default = FollowNotificationPreferences;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FollowNotificationPreferences);
});